import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import info from '../img/info.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import '../App.css'

const Info = () => {


  return (
    <>
      <IconosVolver to={'/'} />
      <Encabezado />
      <IconPage image={info} indice={'Convocatoria'} />
      <div className="container">
        <div className="columns">
          <div className="column has-text-left mt-6 mb-6">
            
            <p className="mt-5 mb-5">
            Por iniciativa de nuestro Secretario General, Cro. Walter Merkis, la Mesa Ejecutiva Nacional de la FATUN
            ha resuelto convocar a un Consejo Directivo Ampliado, para el domingo 26 noviembre de 2023, a las 10 horas, en Horco Molle provincia de Tucumán, con motivo de conmemorar
            el 50° Aniversario del histórico Congreso General Extraordinario, donde quedó sellada para siempre la Unidad de nuestros Sindicatos sobre la base de la Solidaridad y dentro de nuestra Organización Sindical Nacional.
            </p>
            <p className="mt-2 mb-2">
            Detalle de la convocatoria:
            - La jornada del 26 de noviembre contemplará, en Horco Molle, el Acto Aniversario y descubrimiento
            de Placas Conmemorativa, con la presencia de la Mesa Ejecutiva, Delegadas/os al Consejo Directivo Ampliado, Trabajadoras/es NODOCENTES de la APUNT, Autoridades de la UNT, de los
            Ministerios de Educación y Trabajo de la Nación, de la Gobernación y la Municipalidad de Tucumán, como así también Compañeras/os de CGT Nacional y Regional, entre otras/os invitadas/os especiales.
            </p>
            <p className="mt-2 mb-2">
            Sigamos en Unidad, Solidaridad y Organización.
            </p>
          </div>
        </div>
      </div >


      <Footer texto={'federación argentina del trabajador de las universidades nacionales'} />


    </>
  )
}

export default Info
