
const Footer = ({texto}) => { 

    const style = {
        footer: { 
            borderTop: '3px solid #BDBDBD',
            //position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            width: '90%',
            margin: 'auto',
            padding: '1rem 0',
            textAlign: 'center',
            background: '#fff',
        },
        text: {
            color:'#4a4a4a',
        } 
        
    }


    return (
        <>
            <a href='https://www.fatun.org.ar/'>
            <div style={style.footer}>
                <p className='poppins-bold is-uppercase size' style={style.text}>{texto}</p>
            </div>
            </a>
        
        </>
        
    )
}

export default Footer;