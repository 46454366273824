import React from 'react'
import '../App.css'
import logoCongreso from '../img/logocongreso.svg'

function InConstruction() {
  return (
    <>
      <div className="columns is-mobile has-text-centered mt-6">
        <div className="column sectionConstruction">
          <header className='headerConstrucion p-6 '>
          <img src={logoCongreso}  alt='logo congreso'/>
          </header>
          <h1 className='titleConstruction'>¡Bienvenidos!</h1>

          <p className='m-6'>
            Estamos emocionados de anunciar que estamos trabajando arduamente para crear un sitio web que será la plataforma para nuestro próximo evento. Actualmente, estamos en la fase de construcción y diseño para asegurarnos de que el sitio sea fácil de navegar, informativo y esté lleno de contenido interesante.
          </p>

          <p className='m-6'>
            Mientras tanto, te invitamos a seguirnos en nuestras redes para mantenerte actualizado sobre el evento. No queremos que te pierdas ninguna actualización información importante.
          </p>

          <p className='m-6 subtitle'>
            Gracias por tu paciencia y apoyo. ¡Esperamos verte pronto en nuestro evento!
          </p>
        </div>
      </div>


    </>
  )
}

export default InConstruction