import '../App.css'


const HoraCharla = ({hora, tema}) => { 
    return (
        <div style={{alignSelf:'start'}} className='poppins-bold color'>
            <p className= 'is-size-4-desktop is-size-6-mobile is-italic' style={{textAlign:'center'}}>{hora}</p>
        </div>
    )
}

export default HoraCharla;


