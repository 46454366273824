import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => {
    return (
    <div className='mb-6'>
        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Sábado 25 de noviembre</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'09'}/>
            <div>
                <DetalleCharla tema={'Llegada de Delegaciones.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'21:30'}/>
            <div>
                <DetalleCharla tema={'Cena.'} />
            </div>
        </div>


        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
                <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Domingo 26 de noviembre</p>
             </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'7 a 9'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'10'}/>
            <div>
                <DetalleCharla tema={'Inicio del Consejo Directivo Ampliado.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'10'}/>
            <div>
                <DetalleCharla tema={'Descubrimiento de Mural representativo de la Unidad NODOCENTE.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'11'}/>
            <div>
                <DetalleCharla tema={'Acto con presencia de Autoridades Nacionales y Provinciales'} />
                <p>Reconocimiento a los compañeros que participaron del Congreso Unificador</p>
                <p>Trailer del Documental “FATUN Un Camino de Unidad”</p>
                <p>Presentación del libro Soy NODOCENTE</p>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'13 a 15'}/>
            <div>
                <DetalleCharla tema={'Almuerzo en la Carpa.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'16 a 17:30'}/>
            <div>
                <DetalleCharla tema={'Proyección de la Película en Salón.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'17:30 a 18:30'}/>
            <div>
                <DetalleCharla tema={'Merienda.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'20:30'}/>
            <div>
                <DetalleCharla tema={'Sale primera tanda de 80 Compañeras/os Nodocentes en dos micros rumbo a la Cena en el Club.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'20:45'}/>
            <div>
                <DetalleCharla tema={'Sale la segunda tanda de 80 Compañeras/os Nodocentes 2 micros rumbo a la Cena en el Club.'} />
            </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'22'}/>
            <div>
                <DetalleCharla tema={'Cena NODOCENTE en el Club Central Córdoba.'} />
            </div>
        </div>

        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Lunes 27 de noviembre</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'09 a 11'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>

    </div>
    )
}

export default ItinerarioContainer
