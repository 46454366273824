import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import book from '../img/book.svg'
import map from '../img/map.svg'
import '../App.css'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import Encabezado from "../componentes/Encabezado";

const Ubicacion = ({ image, indice, height }) => {

    const style = {
        button: {
            color: '#fff',
            padding: '1rem',
            background: '#3EC3FF',
            width: '100%',
            border: 'none',
            fontSize: '1.5rem'
        }
    }
    return (
        <>

            <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
                <Encabezado/>
                <IconosVolver to={'/'} />
                <IconPage image={book} indice={'libro digital "SOY NODOCENTE"'} />

                <div className="bookIframe pt-4">
                    <iframe className='bookIframe' src="https://zaro.com.ar/fatun/libro/book/" width="900" height="650" frameborder="0" style={{ border: 0, }} allowfullscreen="" aria-hidden="false" tabindex="0">
                    </iframe>
                </div>

            </Layout>

        </>
    )
}


export default Ubicacion;
