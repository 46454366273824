import { Link } from 'react-router-dom'
import iconVolver from '../img/chevron-left.svg'

const style = { 
    position: 'absolute',
    top: '11.5%',
    left:'1.2rem',
    cursror: 'pointer',
    maxWidth:'50px',
    zIndex: '100'
}

const IconosVolver=({to})=>{
    return(
        <>
            <Link to={to}><img src={iconVolver} style={style}/></Link>
        </>
    )
}


export default IconosVolver