import '../App.css'

const style= { 
    color: '#28AAE1',
    fontSize: '1.2rem'
}

const DetalleCharla = ({tema}) => { 
    return (
        <p style={style} className='poppins-bold is-size-4-desktop'>{tema}</p>
    )
}

export default DetalleCharla;