import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import ubicacion from '../img/ubicacion.svg'
import map from '../img/map.svg'
import '../App.css'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import Encabezado from "../componentes/Encabezado";

const Ubicacion = ({ image, indice, height }) => {

    const style = {
        button: {
            color: '#fff',
            padding: '1rem',
            background: '#3EC3FF',
            width: '100%',
            border: 'none',
            fontSize: '1.5rem'
        }
    }
    return (
        <>

            <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
                <Encabezado/>
                <IconosVolver to={'/'} />
                <IconPage image={ubicacion} indice={'ubicación'} />


                <div className="mapIframe pt-4">
                    <h2 className="titleHotel mt-6">RESIDENCIA UNIVERSITARIA HORCO MOLLE UNT</h2>
                    <h3 className="mb-4"><strong>C. A Horco Molle, T4105, Tucumán.</strong></h3>
                    <iframe className='imgIframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.9070650061094!2d-65.32837623794785!3d-26.7792322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942242815ef45541%3A0xcac4e41ab3ad4e16!2sResidencias%20Universitarias%20UNT!5e0!3m2!1ses!2sar!4v1700680592609!5m2!1ses!2sar" width="600" height="450" frameborder="0" style={{ border: 0, }} allowfullscreen="" aria-hidden="false" tabindex="0">
                    </iframe>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
                    <a href='https://www.google.com/maps/dir//Residencias+Universitarias+UNT,+C.+A+Horco+Molle,+T4105,+Tucumán/@-26.7792322,-65.3283762,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x942242815ef45541:0xcac4e41ab3ad4e16!2m2!1d-65.3258013!2d-26.7792322?entry=ttu' target="_blank">
                        <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
                    </a>
                </div>
            </Layout>

        </>
    )
}


export default Ubicacion;
